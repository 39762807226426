import Axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router';
import { Badge, Button, ButtonToggle, Label, Table, UncontrolledTooltip } from 'reactstrap';
import '../assets/scss/autoships.scss';
import '../assets/scss/dumbcomponent-styles.scss';
import '../assets/scss/autoshipdetailspage.scss';
import LoadingDiv from './components/LoadingComponents/LoadingDiv';
import DataTable from 'react-data-table-component';
import { FaCheckCircle, FaExclamationCircle, FaFileCsv, FaMinus, FaProjectDiagram, FaSitemap, FaUsers, FaUserSlash } from 'react-icons/fa';
import moment from 'moment';
import * as _ from "lodash";
import { group } from 'console';
import { useToastContext } from '../contexts/ToastContext';
import arrayToTree from 'array-to-tree';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';

interface AutoshipDetailTableProps extends React.HTMLAttributes<HTMLDivElement> {
    data: any;
    type: "unilevel" | "placement";
    downlineOf: string;
}

const AutoshipDetailTable: FC<AutoshipDetailTableProps> = props => {
    const history = useHistory();
    const addToast = useToastContext();
    const [downlineRecords, setDownlineRecords] = useState<any[] | undefined>(undefined);
    const downlineRecordsRequest = useAsyncCallback(() => Axios.get("/api/autoship/downline", { params: { type: props.type } }).then(response => {
        setDownlineRecords([].concat.apply([], response.data.latestDownlineUploads
            .map((upload: any) => upload.records
                .map((record: any) => ({
                    ...record, uploadedBy: response.data.downlineUsers
                        .find((user: any) => user._id.toString() === upload.userId.toString())
                })))));
        if (response.data.latestDownlineUploads.length > 0) {
            addToast({
                title: "Downline data successfully synchronized",
                content: "You can now view the autoship data uploaded by your downline.",
                status: "success",
            });
        } else {
            addToast({
                title: "No downline data found",
                content: "There is no active team member in your downline, that uses the IM Leaders Kit",
                status: "information",
            });
        }
        return response.data;
    }).catch(() => {
        addToast({
            title: "No downline data found",
            content: "There is no active team member in your downline, that has the IM Leaders Kit",
            status: "information",
        });
    }));

    const isDownlineOf = (data: any[], entry: any, memberId: string): boolean => {
        if (!(memberId && entry && data && data.length > 0)) {
            return false;
        }

        const member = data.find(rec => rec.id === memberId);

        if (!member)
            return false;

        const parent = data.find((rec) =>
            rec.name.startsWith(entry.placementSponsor)
        );
        if (!parent)
            return false;

        if (parent.id === memberId) {
            return true;
        } else {
            return isDownlineOf(data, parent, memberId);
        }
    }

    const [processTable, setProcessTable] = useState<boolean>(false);
    const [showInactive, setShowInactive] = useState<boolean>(false);

    const convertArrayOfObjectsToCSV = (array: any[]) => {
        const columnDelimiter = ',';
        const lineDelimiter = '\n';

        const excludedCols = ["status", "uploadedBy"];

        const keys = !processTable
            ? processedColumns.filter(col => !excludedCols.includes(col.selector || "")).map(col => col.selector)
            : rawColumns.filter(col => !excludedCols.includes(col.selector || "")).map(col => col.selector);

        return (!processTable
            ? processedColumns.filter(col => !excludedCols.includes(col.selector || "")).map(col => col.name)
            : rawColumns.filter(col => !excludedCols.includes(col.selector || "")).map(col => col.name))
            + lineDelimiter + array
                .filter(col => !excludedCols.includes(col.selector || ""))
                .map(elem => keys.map(key => key && ![undefined, null].includes(elem[key]) ? (elem[key].toLocaleDateString ? elem[key].toLocaleDateString() : elem[key]) : "undefined").join(columnDelimiter)).join(lineDelimiter);
    };

    const downloadCSV = (records: any[]) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(records);
        if (!csv) return;

        const filename = props.type + "_autoshipList_" + moment(props.data.createdAt).format("yyyy_MM_dd_hh_mm_ss") + ".csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:application/octet-stream;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const Export = (props: { onExport: () => void }) => <Button onClick={() => props.onExport()}><FaFileCsv /> Export as CSV</Button>;
    const ViewTree = () => <Button color="primary" onClick={() => history.push(history.location.pathname + "/tree")}><FaSitemap /> View Tree <Badge color="danger">NEW</Badge></Button>;
    const ToggleProcessTableButton = () => <ButtonToggle onClick={() => setProcessTable(!processTable)} color="primary"><FaProjectDiagram /> {processTable ? "Show processed table" : "Show raw table"}</ButtonToggle>;
    const ShowInactive = () => <ButtonToggle color="primary" onClick={() => setShowInactive(!showInactive)}><FaUserSlash /> {showInactive ? "Hide inactive partners" : "Show inactive partners"}</ButtonToggle>;
    const LoadDownlineRecords = () => <ButtonToggle color="primary" onClick={() => downlineRecordsRequest.execute()}><FaUsers /> Synchronize downline data</ButtonToggle>;

    const actionsMemo = React.useMemo(() => (
        <div>
            <ToggleProcessTableButton />{" "}
            {props.type === "placement" && !props.downlineOf && <><ViewTree />{" "}</>}
            <ShowInactive />{" "}
            <LoadDownlineRecords />{" "}
            <Export onExport={() => downloadCSV(props.data.records)} />
        </div>
    ), [props.data, props.downlineOf, showInactive, processTable]);

    const getStatus = (row: any) => {
        const nextDate = new Date(row.nextDate);
        nextDate.setHours(0, 0, 0, 0);

        if (!row.lastPaymentDate || !Date.parse(row.lastPaymentDate))
            return <span style={{ color: "red", fontSize: "1.1rem" }}><FaMinus /></span>

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const diff = moment(nextDate).diff(moment(today), "hours");

        if (Math.floor(diff / 24) <= 0)
            return <>
                <span style={{ color: "gray", fontSize: "1.1rem" }} id={"status-symbol-" + row.id}><FaExclamationCircle /></span>
                <UncontrolledTooltip autohide={false} placement="right" target={"status-symbol-" + row.id}>
                    <strong>{diff < 0 ? "This member has expired" : "Attention: This member is about to expire"}</strong>
                </UncontrolledTooltip>
            </>

        if (diff <= 168)
            return <>
                <span style={{ color: diff <= 72 ? "red" : "orange", fontSize: "1.1rem" }} id={"status-symbol-" + row.id}><FaExclamationCircle /></span>
                <UncontrolledTooltip autohide={false} placement="right" target={"status-symbol-" + row.id}>
                    Warning: This member is expiring in {<strong>{Math.ceil(diff / 24)} {diff > 24 ? "days" : "day"}</strong>}
                </UncontrolledTooltip>
            </>

        return <span style={{ color: "green", fontSize: "1.1rem" }}><FaCheckCircle /></span>
    };

    const rawColumns = [
        {
            name: "Synced by",
            selector: "uploadedBy",
            cell: (row: any) => row.uploadedBy ? (row.uploadedBy.firstname + " " + row.uploadedBy.lastname) : "You",
            grow: 0,
            omit: [[], undefined].includes(downlineRecords as any),
            sortable: true
        },
        {
            name: "Status",
            selector: "status",
            cell: (row: any) => getStatus(row),
            width: "75px",
            grow: 0,
            sortable: false
        },
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            grow: 0
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: (row: any) => <Button color="link"
                onClick={() => {
                    history.replace(
                        history.location.pathname.includes(props.downlineOf)
                            ? history.location.pathname.replace(props.downlineOf, row.id)
                            : history.location.pathname.split("/").concat([row.id]).join("/")
                    );
                }}>{row.name}</Button>,
            grow: 3
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            grow: 0
        },
        {
            name: 'Join Date',
            selector: "joinDate",
            sortable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{new Date(row.joinDate).toLocaleDateString()}</div>,
            grow: 0
        },
        {
            name: 'Rank',
            selector: 'rank',
            sortable: true,
            grow: 3
        },
        {
            name: 'IBO Kit',
            selector: 'iboKit',
            sortable: true,
            grow: 0
        },
        {
            name: 'Level',
            selector: 'level',
            sortable: true,
            grow: 0
        },
        {
            name: 'PV',
            selector: 'pv',
            sortable: true,
            grow: 0,
            width: "75px"
        },
        {
            name: 'PSV',
            selector: 'psv',
            sortable: true,
            grow: 0,
            width: "75px"
        },
        {
            name: 'PRSV',
            selector: 'prsv',
            sortable: true,
            grow: 0,
            width: "75px"
        },
        {
            name: 'Total GV',
            selector: 'totalGv',
            sortable: true,
            grow: 0,
            width: "75px"
        },
        {
            name: 'Total Customer GV',
            selector: 'totalCustomerGv',
            sortable: true,
            grow: 0
        },
        {
            name: '55/45 GV',
            selector: 'iboCleanedGv',
            sortable: true,
            grow: 0
        },
        {
            name: 'Placement Sponsor',
            selector: 'placementSponsor',
            sortable: true,
            grow: 3
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: true,
            resizeable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{row.active == true ? "Yes" : "No"}</div>,
            grow: 0,
            width: "60px",
            omit: !showInactive
        },
        {
            name: 'Last Payment Date',
            selector: "lastPaymentDate",
            sortable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{Date.parse(row.lastPaymentDate) && new Date(row.lastPaymentDate).toLocaleDateString() || "No Payment"}</div>,
            grow: 1
        },
        {
            name: 'Next Billing Date',
            selector: "nextDate",
            sortable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{Date.parse(row.lastPaymentDate) && new Date(row.nextDate).toLocaleDateString() || "No Payment"}</div>,
            grow: 1
        }
    ];

    const processedColumns = [
        {
            name: "Synced by",
            selector: "uploadedBy",
            cell: (row: any) => row.uploadedBy ? (row.uploadedBy.firstname + " " + row.uploadedBy.lastname) : "You",
            grow: 0,
            omit: [[], undefined].includes(downlineRecords as any),
            sortable: true
        },
        {
            name: "Status",
            selector: "status",
            cell: (row: any) => getStatus(row),
            width: "75px",
            grow: 0,
            sortable: false
        },
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            grow: 0
        },
        {
            name: 'Name',
            cell: (row: any) => <Button color="link"
                onClick={() => {
                    history.replace(
                        history.location.pathname.includes(props.downlineOf)
                            ? history.location.pathname.replace(props.downlineOf, row.id)
                            : history.location.pathname.split("/").concat([row.id]).join("/")
                    );
                }}>{row.name}</Button>,
            sortable: true,
            grow: 2
        },
        {
            name: 'Placement Sponsor',
            selector: 'placementSponsor',
            sortable: true,
            grow: 2
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: true,
            resizeable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{row.active == true ? "Yes" : "No"}</div>,
            grow: 0,
            width: "60px",
            omit: !showInactive
        },
        {
            name: 'Last Payment Date',
            selector: "lastPaymentDate",
            sortable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{Date.parse(row.lastPaymentDate) && new Date(row.lastPaymentDate).toLocaleDateString() || "No Payment"}</div>
        },
        {
            name: 'Next Billing Date',
            selector: "nextDate",
            sortable: true,
            cell: (row: any) => <div data-tag="allowRowEvents">{Date.parse(row.lastPaymentDate) && row.active && new Date(row.nextDate).toLocaleDateString() || "No Payment"}</div>,
        }
    ];

    const conditionalRowStyles = [
        {
            when: (row: any) => row.uploadedBy !== undefined,
            style: {
                backgroundColor: "#edfffd"
            },
        },
    ];

    const ExpandableRowsComponent = (data: any) => <div className="p-3" style={{ backgroundColor: "#efefef" }}>
        <DataTable
            highlightOnHover
            keyField="id"
            columns={outputCols}
            defaultSortField="nextDate"
            title={"Other record syncs"}
            data={data.data.earlierUploads}
            conditionalRowStyles={conditionalRowStyles}
            paginationTotalRows={data ? data.length : 0}
        />
    </div>

    const outputCols = processTable ? rawColumns : processedColumns;

    const genData = () => {
        const rawData = ((downlineRecordsRequest.result && downlineRecords)
            ? downlineRecords
            : []).concat(props.data.records
                .filter((record: any) => showInactive || record.active));

        const groupedRecords = _.groupBy(rawData, "id");
        const result: any[] = [];

        Object.keys(groupedRecords).forEach((key, index) => {
            const currentRows = groupedRecords[key];
            const latestUploadDate = new Date(Math.max(...currentRows.map((e: any) => new Date(e.createdAt || props.data.createdAt).getTime())));

            const latestUploadedRow = currentRows.map(x => ({ ...x, createdAt: x.createdAt || props.data.createdAt })).find((row: any) => new Date(row.createdAt).getTime() === latestUploadDate.getTime());
            result.push({
                ...latestUploadedRow,
                earlierUploads: currentRows.map(row => ({ ...row, uniqueId: row.createdAt + "-" + row.id })).filter(row => row.uploadedBy !== latestUploadedRow.uploadedBy),
                uniqueId: latestUploadedRow.createdAt + "-" + latestUploadedRow.id
            });
        });

        const filteredResults: any[] = result
            .filter(entry => !props.downlineOf || isDownlineOf(result, entry, props.downlineOf))
            .filter(row => row.active || showInactive);

        return filteredResults;
    };

    const getDataTable = () => {
        const data = genData();
        const pagination = [];

        if (data) {
            for (let index = Math.min(15, data.length); index < data.length; index *= 2) {
                pagination.push(index);
            }
            pagination[pagination.length - 1] !== data.length && pagination.push(data.length)
        }
        return <DataTable
            highlightOnHover
            striped
            expandOnRowClicked
            expandableRows={downlineRecords && downlineRecords.length > 0}
            expandableRowsComponent={<ExpandableRowsComponent />}
            expandableRowDisabled={(row: any) => row.earlierUploads.length <= 0}
            keyField="id"
            pagination={true}
            paginationPerPage={pagination[0]}
            paginationRowsPerPageOptions={pagination}
            columns={outputCols}
            defaultSortField="nextDate"
            title={<span>Autoship <strong>{new Date(props.data.createdAt).toLocaleDateString()} {new Date(props.data.createdAt).toLocaleTimeString().substr(0, 5)}</strong></span>}
            data={data}
            actions={actionsMemo}
            conditionalRowStyles={conditionalRowStyles}
            paginationTotalRows={data ? data.length : 0}
        />
    };

    return getDataTable();
};

export default AutoshipDetailTable;